

























































































































































































































import { defineComponent, onBeforeMount, reactive } from '@vue/composition-api';
import TripTaskCard from '@/components/trip/trip-task-cards/TripTaskCard.vue';
import dateFormats from '@/helpers/DateFormats';
import { DateComparer } from '@/helpers/DateComparer';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';
import { parseISO } from 'date-fns';
import PlaceInfo from '@/components/trip/trip-task-card-concrete-info/PlaceInfo.vue';
import LocoSectionGraphs from '@/components/graph/LocoSectionGraphs.vue';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { UrlConstants } from '@/helpers/UrlConstants';
import { AxiosResponse } from 'axios';
import { Trip } from '@/models/trip/Trip';
import LocoSectionWithParameters from '@/models/graph/LocoSectionWithParameters';
import FuelConsumptionTable from '@/components/trip/trip-task-cards/FuelConsumptionTable.vue';
import PowerConsumptionTable from '@/components/trip/trip-task-cards/PowerConsumptionTable.vue';

export default defineComponent({
  name: 'LocomotiveReturnTaskCard',
  components: {
    PowerConsumptionTable, FuelConsumptionTable, LocoSectionGraphs, PlaceInfo, TripTaskCard,
  },
  props: {
    locomotiveReturn: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    const httpService = Container.resolve<HttpService>("HttpService");
    const { toTime } = dateFormats();

    const compareDates = DateComparer.compareToMinutes;
    const { firstDateIsGreater } = DateComparer;

    const reactiveData = reactive({
      parameters: null as LocoSectionWithParameters | null,
    });

    const parseDate = (dateString: string) => {
      return parseISO(dateString);
    };

    onBeforeMount(async () => {
      const { code } = props.locomotiveReturn.tripTask.tractionKind;
      const url = `${UrlConstants.baseApi}/v1/OnlineLocomotiveParameters?tractionKindCode=${code}`;
      let response: AxiosResponse<LocoSectionWithParameters>;
      try {
        response = await httpService.get<LocoSectionWithParameters>(url, {
          constructorFunction: LocoSectionWithParameters,
        });
      } catch (e) {
        return;
      }

      reactiveData.parameters = response.data;
    });

    return {
      parseDate,
      toTime,
      reactiveData,
      compareDates,
      firstDateIsGreater,
      TripTaskTypeKeys: { ...TripTaskTypeKeys },
    };
  },
});
