






























































































































































































































import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FuelConsumptionTable',
  props: {
    fuelConsumptions: {
      type: Array,
      required: true,
    },
  },
  setup: (props) => {
    const consumptionNorm = computed(() => props.fuelConsumptions
      .map((fc: any) => fc.norm)
      .reduce((a, b) => a + b, 0));

    const actualConsumption = computed(() => props.fuelConsumptions
      .map((fc: any) => fc.consumption)
      .reduce((a, b) => a + b, 0));

    return {
      consumptionNorm,
      actualConsumption,
    };
  },
});
