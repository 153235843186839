import { Type } from 'class-transformer';

export default class ParameterForGraph {
  public name = ''

  public value = ''

  @Type(() => Date)
  public date!: Date
}
