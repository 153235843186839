






































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Trip } from '@/models/trip/Trip';
import { Route } from '@/models/trip/Route';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'RoutesInTripPanel',
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
  },
  setup: (props) => {
    const { toTime, toTextMonthDate } = dateFormats();

    const routes = computed(() => props.trip.lastVersion.continuousWorks
      .flatMap((cw) => cw.routes));

    const getRouteBeginTime = (route: Route): string => {
      return toTime(route.routeEvents[0].date);
    };

    const getRouteEndTime = (route: Route): string => {
      const { length } = route.routeEvents;
      return toTime(route.routeEvents[length - 1].date);
    };

    return { routes, getRouteBeginTime, getRouteEndTime };
  },
});
