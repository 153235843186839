












































































































































































































import { defineComponent } from '@vue/composition-api';
import TripTaskCard from '@/components/trip/trip-task-cards/TripTaskCard.vue';
import dateFormats from '@/helpers/DateFormats';
import { DateComparer } from '@/helpers/DateComparer';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';
import { parseISO } from 'date-fns';
import PlaceInfo from '@/components/trip/trip-task-card-concrete-info/PlaceInfo.vue';

export default defineComponent({
  name: 'FuelEquipmentTaskCard',
  components: { PlaceInfo, TripTaskCard },
  props: {
    fuelEquipment: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    const { toTime } = dateFormats();

    const compareDates = DateComparer.compareToMinutes;
    const { firstDateIsGreater } = DateComparer;

    const parseDate = (dateString: string) => {
      return parseISO(dateString);
    };

    const calculateFuel = (locoSectionsEvents: any[]) => {
      let fuel = 0;

      locoSectionsEvents.forEach((lse) => {
        fuel += lse.fuelQuantity;
      });

      return fuel;
    };

    return {
      parseDate,
      toTime,
      compareDates,
      firstDateIsGreater,
      calculateFuel,
      TripTaskTypeKeys: { ...TripTaskTypeKeys },
    };
  },
});
