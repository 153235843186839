








import { defineComponent } from '@vue/composition-api';
import { PieChart } from 'vue-chart-3';

export default defineComponent({
  name: 'WorkAnalyticsInMoneyPie',
  components: {
    PieChart,
  },
  setup: () => {
    const data = {
      labels: ['Прибыль', 'Расходы'],
      datasets: [
        {
          data: [30, 70],
          backgroundColor: ['#739B02', '#F64B4B'],
        },
      ],
    };

    return { data };
  },
});
