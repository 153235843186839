















import { defineComponent, PropType } from '@vue/composition-api';
import { LineChart } from 'vue-chart-3';
import { LocomotiveReturnEventModel } from '@/models/trip/concrete-trip-event-with-task/LocomotiveReturnEventModel';

export default defineComponent({
  name: 'FuelInTrip',
  components: {
    LineChart,
  },
  props: {
    locomotiveReturn: {
      type: Object as PropType<LocomotiveReturnEventModel>,
      required: true,
    },
  },
  setup: (props) => {
    // const testData = {
    //   labels: ['Доход', 'Прибыль', 'Расход', ],
    //   datasets: [
    //     {
    //       data: [70, 20, 10,],
    //       backgroundColor: ['#FBC60A', '#739B02', '#F64B4B',],
    //     },
    //   ],
    // };

    const event = props.locomotiveReturn.tripEvent;

    let fuelData;

    if (event.fuelConsumption.length > 0) {
      fuelData = {
        labels: ['Доход'],
        dataset: [
          {
            label: 'Тест',
            data: [10, 20, 30],
            // borderColor: '#613361',
            backgroundColor: ['#FBC60A', '#739B02', '#F64B4B'],
          },
        ],
      };
    } else {
      fuelData = {
        labels: ['Доход'],
        dataset: [
          {
            label: 'Тест',
            data: [10, 20, 30],
            // borderColor: '#613361',
            backgroundColor: ['#FBC60A', '#739B02', '#F64B4B'],
          },
        ],
      };
    }

    return { fuelData };
  },
});
