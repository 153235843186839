















import { defineComponent } from '@vue/composition-api';
import { HubConnectionState } from '@microsoft/signalr';

export default defineComponent({
  name: 'ConnectionStatePanel',
  props: {
    connectionState: {
      type: String,
      required: true,
    },
  },
  setup: () => {
    const localizeStatus = (state: HubConnectionState) : string => {
      switch (state) {
        case HubConnectionState.Disconnected:
          return 'Отключен';
        case HubConnectionState.Connecting:
          return 'Подключение...';
        case HubConnectionState.Connected:
          return 'Подключен';
        case HubConnectionState.Disconnecting:
          return 'Отключение...';
        case HubConnectionState.Reconnecting:
          return 'Переподключение...';
      }
    };

    return {
      localizeStatus,
    };
  },
});
