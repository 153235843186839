






/* tslint:disable */
import Highcharts, {
  Chart,
} from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import ParameterForGraph from '@/models/Graph/ParameterForGraph';
import { defineComponent, onMounted, PropType } from '@vue/composition-api';
import LocoSectionWithParameters from '@/models/graph/LocoSectionWithParameters';

export default defineComponent({
  name: 'MultiGraph',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    exportingInit(Highcharts);
    const series = [];

    for (const key in props.data) {
      series.push({
        name: key,
        data: props.data[key].map((d: any) => [new Date(d.date).getTime(), Number(d.value)]),
      });
    }

    const chartOptions = {
      title: {
        text: 'Показания',
      },
      series,
      xAxis: {
        type: 'datetime',
      },
      height: 150,
      scrollbar: {
        enabled: true,
      },
      id: Math.random(),
      tooltip: {
        shared: true,
      },
    };

    const values = () => {
      const formatedData = props.data.map((d: any) => [new Date(d.date), Number(d.value)]);
      return formatedData;
    };

    const scroll = (e: any) => {
      // for (let i = 0; i < Highcharts.charts.length; ++i) {
      //   const chart = Highcharts.charts[i] as Chart;
      //   const event = chart.pointer.normalize(e);
      //   let point;
      //   for (let j = 0; j < chart.series.length && !point; ++j) {
      //     point = (chart.series[j] as any).searchPoint(event, true);
      //   }
      //   if (!point) return;
      //
      //   if (e.type === 'mousemove') {
      //     point.onMouseOver();
      //     chart.xAxis[0].drawCrosshair(event, point);
      //   } else {
      //     point.onMouseOut();
      //     chart.tooltip.hide(point);
      //     chart.xAxis[0].hideCrosshair();
      //   }
      // }
    };

    onMounted(() => {
      // const chart = Highcharts.charts.find((c) => (c?.options as any).id == chartOptions.id)!;
      // const data = props.data.filter((llp) => llp.name == props.parameterName).map((d: any) => [new Date(d.date).getTime(), Number(d.value)]);
      // allData = allData.concat(data as any);
      // chart.series[0].setData(allData, true);
      // chart.series = series;

      // for (let i = 0; i < series.length; i++) {
      //   chart.series[i].setData(series[i];
      // }
    });

    return {
      chartOptions,
      scroll,
    };
  },
});
