







import LocoSectionWithParameters from '@/models/graph/LocoSectionWithParameters';
import { defineComponent, PropType } from '@vue/composition-api';
import MultiGraph from '@/components/graph/MultiGraph.vue';
import Graph from './Graph.vue';

export default defineComponent({
  name: 'LocoSectionGraphs',
  components: {
    MultiGraph,
    Graph,
  },
  props: {
    locoSectionWithParameters: {
      type: Object as PropType<LocoSectionWithParameters>,
      required: true,
    },
  },
  setup: (props) => {
    const groupBy = (array: [], key: string) => {
      const result = {} as any;
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    };

    const locoSectionParameters = () => {
      const grouped = groupBy(props.locoSectionWithParameters.parameters as [], 'name');
      return grouped;
    };

    return {
      groupBy,
      locoSectionParameters,
    };
  },
});
