










































import { defineComponent } from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'PlanChangedCard',
  props: {
    planChanged: {
      type: Object,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const { toTime } = dateFormats();

    return {
      toTime,
    };
  },
});
