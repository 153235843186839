









































































































































































































































import {
  defineComponent, nextTick, onBeforeMount, onBeforeUnmount, reactive, ref, watch,
} from '@vue/composition-api';
import { Container } from '@/Container';
import { HttpService } from '@/services/network/HttpService';
import { UrlConstants } from '@/helpers/UrlConstants';
import { AxiosResponse } from 'axios';
import { Trip } from '@/models/trip/Trip';
import dateFormats from '@/helpers/DateFormats';
import { DateComparer } from '@/helpers/DateComparer';
import { TripOnlineConnection } from '@/services/network/online-services/TripOnlineConnection';
import TripTaskCard from '@/components/trip/trip-task-cards/TripTaskCard.vue';
import CrewChain from '@/components/crew/CrewChain.vue';
import CrewInfoPanel from '@/components/crew/CrewInfoPanel.vue';
import RoutesInTripPanel from '@/components/trip/RoutesInTripPanel.vue';
import IommProcessStatus from '@/components/trip/IommProcessStatus.vue';
import WorkAnalyticsInMoney from '@/components/trip/WorkAnalyticsInMoney.vue';
import WorkAnalyticsByServices from '@/components/trip/WorkAnalyticsByServices.vue';
import WorkAnalyticsByMainCriteria from '@/components/trip/WorkAnalyticsByMainCriteria.vue';
import WorkAnalyticsByOperations from '@/components/trip/WorkAnalyticsByOperations.vue';
import LocomotiveCrewWorkAnalytics from '@/components/trip/LocomotiveCrewWorkAnalytics.vue';
import LocomotiveWorkAnalytics from '@/components/trip/LocomotiveWorkAnalytics.vue';
import FuelAnalytics from '@/components/trip/FuelAnalytics.vue';
import AccountingForReceivedServices from '@/components/trip/AccountingForReceivedServices.vue';
import PlanChangedCard from '@/components/trip/PlanChangedCard.vue';
import FuelInTrip from '@/components/trip/FuelInTrip.vue';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';
import LocomotiveAcceptanceTaskCard
  from '@/components/trip/trip-task-cards/LocomotiveAcceptanceTaskCard.vue';
import LocomotiveReturnTaskCard
  from '@/components/trip/trip-task-cards/LocomotiveReturnTaskCard.vue';
import FuelEquipmentTaskCard from '@/components/trip/trip-task-cards/FuelEquipmentTaskCard.vue';
import WagonBindingTaskCard from '@/components/trip/trip-task-cards/WagonBindindTaskCard.vue';
import RestTaskCard from '@/components/trip/trip-task-cards/RestTaskCard.vue';
import PassengerTravelTaskCard from '@/components/trip/trip-task-cards/PassengerTravelTaskCard.vue';
import { useTitle } from '@/composables/PageTitle';
import ConnectionState from '@/components/trip/ConnectionStatePanel.vue';
import ConnectionStatePanel from '@/components/trip/ConnectionStatePanel.vue';
import TripSummaryPanel from '@/components/trip/TripSummaryPanel.vue';
import { useRoute, useRouter } from '@/router/router-composition-api';
import { Route } from '@/models/trip/Route';
import { TripEventModelWithTask } from '@/models/trip/TripEventModelWithTask';

export default defineComponent({
  name: 'Trip',
  components: {
    TripSummaryPanel,
    ConnectionStatePanel,
    ConnectionState,
    PassengerTravelTaskCard,
    RestTaskCard,
    WagonBindingTaskCard,
    FuelEquipmentTaskCard,
    LocomotiveReturnTaskCard,
    LocomotiveAcceptanceTaskCard,
    FuelInTrip,
    PlanChangedCard,
    AccountingForReceivedServices,
    FuelAnalytics,
    LocomotiveWorkAnalytics,
    LocomotiveCrewWorkAnalytics,
    WorkAnalyticsByOperations,
    WorkAnalyticsByMainCriteria,
    WorkAnalyticsByServices,
    WorkAnalyticsInMoney,
    IommProcessStatus,
    RoutesInTripPanel,
    CrewInfoPanel,
    CrewChain,
    TripTaskCard,
  },
  setup: (props, ctx) => {
    const animationName = 'animate__rubberBand';
    const { toTime } = dateFormats();
    const currentRoute = useRoute();
    const httpService = Container.resolve<HttpService>("HttpService");
    const tripOnlineConnection = Container.resolve<TripOnlineConnection>("TripOnlineConnection");

    const reactiveData = reactive({
      trip: new Trip(),
      selectedTripVersionId: '',
      onlineState: tripOnlineConnection.state,
      show: false,
    });

    const compareDates = DateComparer.compareToMinutes;
    const { firstDateIsGreater } = DateComparer;

    const getTrip = async () => {
      const url = `${UrlConstants.baseApi}/v1/trips/${currentRoute.query.tripId}/by-id`;
      let response: AxiosResponse<Trip>;
      try {
        response = await httpService.get<Trip>(url, {
          constructorFunction: Trip,
        });
      } catch (e) {
        return;
      }

      reactiveData.trip = response.data;
      reactiveData.selectedTripVersionId = reactiveData.trip.lastVersion.id;
    };

    const routeEventsInLatestVersion = (route: Route) : TripEventModelWithTask[] => {
      return route.routeEvents
        .filter((re) => re.tripVersionId === reactiveData.selectedTripVersionId);
    };

    onBeforeMount(async () => {
      await getTrip();

      const title = useTitle();
      const { toDate } = dateFormats();
      const tripNumber = reactiveData.trip.number;
      const tripDate = toDate(reactiveData.trip.dateBegin);
      const driver = reactiveData.trip.employees.find((e) => e.index === 1)!.employee.initials();

      title.value = `Поездка №${tripNumber} от ${tripDate} ${driver}`;

      tripOnlineConnection.onTripPlanChanged(() => {
        getTrip();
      });

      tripOnlineConnection.onTripEnded(async () => {
        await getTrip();
        setTimeout(() => {
          const iommStatusDiv = document.getElementById('iomm-process-status')!;
          const summaryPanelDiv = document.getElementById('trip-summary-panel')!;
          const workAnalyticsInMoneyDiv = document.getElementById('work-analytics-in-money')!;
          iommStatusDiv.onanimationend = () => {
            iommStatusDiv.classList.remove('animate__animated', animationName);
          };
          summaryPanelDiv.onanimationend = () => {
            summaryPanelDiv.classList.remove('animate__animated', animationName);
          };
          workAnalyticsInMoneyDiv.onanimationend = () => {
            workAnalyticsInMoneyDiv.classList.remove('animate__animated', animationName);
          };
          iommStatusDiv.classList.add('animate__animated', animationName);
          summaryPanelDiv.classList.add('animate__animated', animationName);
          workAnalyticsInMoneyDiv.classList.add('animate__animated', animationName);
        }, 500);
      });

      tripOnlineConnection.onTripChanged((tripEventTask) => {
        const routes = reactiveData.trip.versions
          .flatMap((v) => v.continuousWorks)
          .flatMap((cw) => cw.routes);

        for (const route of routes) {
          const routeEvent = route.routeEvents.find((re) => {
            return re.tripTask?.id == tripEventTask.tripTask.id;
          });

          if (routeEvent != undefined) {
            routeEvent.tripTask = tripEventTask.tripTask;
            routeEvent.tripEvent = tripEventTask.tripEvent;
            setTimeout(() => {
              const taskDiv = document.getElementById(tripEventTask.tripTask.id)!;
              taskDiv.onanimationend = () => {
                taskDiv.classList.remove('animate__animated', animationName);
              };
              taskDiv.classList.add('animate__animated', animationName);
            }, 500);
            break;
          }
        }
      });
      await tripOnlineConnection.start();
    });

    onBeforeUnmount(async () => {
      await tripOnlineConnection.stop();
    });

    return {
      reactiveData,
      toTime,
      compareDates,
      firstDateIsGreater,
      routeEventsInLatestVersion,
      onlineConnectionState: tripOnlineConnection.state,
      TripTaskTypeKeys: { ...TripTaskTypeKeys },
    };
  },
});
