




















import { defineComponent } from '@vue/composition-api';
import WorkAnalyticsInMoneyPie from '@/components/trip/WorkAnalyticsInMoneyPie.vue';

export default defineComponent({
  name: 'WorkAnalyticsInMoney',
  components: { WorkAnalyticsInMoneyPie },
});
