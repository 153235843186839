








































import { defineComponent, PropType } from '@vue/composition-api';
import { EmployeeInCrew } from '@/models/trip/EmployeeInCrew';

export default defineComponent({
  name: 'CrewInfoPanel',
  props: {
    crew: {
      type: Array as PropType<EmployeeInCrew[]>,
      required: true,
    },
  },
});
